
.pagination {
	margin: 0 0 3em 0;

	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: inline;

		&:not(:first-child) {
			margin-left: .5em;
		}

		&.-first::before {
			content: "\007c\003c ";
		}

		&.-previous::before {
			content: "\003c\003c ";
		}

		&.-next::after {
			content: " \003e\003e";
		}

		&.-last::after {
			content: " \003e\007c";
		}
	}

	&__link {

	}
}

@include link-all(".pagination__link[aria-disabled='true']") {
	pointer-events: none;
	text-decoration: none;
	opacity: .65;
}
