//$cache-hash: '?v-'+unique-id() !global; // Unikátní hash pro cestu k obrázkům
/*==============================
	FONTS
==============================*/

$font-system-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
$font-body: Nunito, $font-system-stack;
$font-headings: Teko, $font-system-stack;


/*==============================
	COLORS
==============================*/

$color-base: #838383;
$color-brand: #c0171a;
$color-secondary: #F3F3F3;
$color-tertiary: #2e2e2e;
$color-light: #f6f2ef;
$color-dark: #2e2e2e;


/*==============================
	SIZES
==============================*/

$max-width: 1432px;
$grid-spacing: 40px;

$nav-horizontal-burger-threshold: 600px;

/*==============================
	DEFAULTS
==============================*/

$default-document-link-color: $color-tertiary;

/* COMPONENT VARIABLES */

/*==============================
	FORMS
==============================*/

$form-active-color: #6b9aeb;
$form-active-text: #ffffff;

$form-item-standard-size: 64px;
$form-item-xl-size: 54px;
$form-item-border-color: #cccccc;
$form-item-border-width: 1px;
$form-item-border-radius: 4px;
$form-item-bg: #ffffff;
$form-item-fg: #2a2a2a;
$form-disabled-item-border-color: #e3e3e3;
$form-disabled-item-bg: #ebebeb;
$form-disabled-item-fg: lighten($form-item-fg, 10%);
$form-item-focus-shadow: rgba(0, 0, 0, .12);
$form-icon-color: #cccccc;


/*==============================
	BUTTONS
==============================*/

$button-radius: 4px;
$button-bg: $color-secondary;
$button-fg: $color-dark;
$button-primary-bg: $color-brand;
$button-primary-fg: $color-light;

/*==============================
	TABS
==============================*/

$tab-background: $color-secondary; //pozadí záložky
$tab-text: $color-base; //text záložky
$tab-selected-background: $color-brand; //pozadí aktivní záložky
$tab-selected-text: #fff; //text aktivní záložky

/*==============================
	CALENDAR -PAGE
==============================*/

$cal-page-type-bg: #373737;
$cal-page-type-color: #ffffff;
$cal-page-type-selected-bg: #d9241b;
$cal-page-type-selected-color: #ffffff;

$cal-page-head-color: #373737;

$cal-page-day-bg: #f8f8f8;
$cal-page-day-color:#242424;
$cal-page-day-border-color:#c7c7c7;

$cal-page-day-in-prev-month-bg:#e7e7e7;
$cal-page-day-in-prev-month-color:#979797;

$cal-page-day-in-next-month-bg:$cal-page-day-in-prev-month-bg;
$cal-page-day-in-next-month-color:$cal-page-day-in-prev-month-color;

$cal-page-day-selected-border: $cal-page-type-selected-bg;
$cal-page-day-selected-color: $cal-page-type-selected-bg;

$cal-page-day-today-border:#b11a8b;
$cal-page-day-today-color:#b11a8b;
