$tags-bg-color: $color-brand;
$tags-color: #ffffff;
$tags-bg-color-hover: #e9e6ed;
$tags-color-hover: $color-brand;


.tag{
	text-decoration: none;
	display: inline-block;
	padding: .25rem .5rem;
	border-radius: 3px;
	text-align: center;
	font-weight: 700;
	font-size: rem(14px);
}

@include link(".tag") {
	color: $tags-color;
	text-decoration: none;
	background: {
		color: $tags-bg-color;
		repeat: repeat-x;
		size: auto 200%;
		position: 0 0;
	}
	&.-isLocked{
		background-color: #e1e1e1!important;
		color: #999!important;
	}
}

@include link-over(".tag") {
	background-color: $tags-bg-color-hover;
	color: $tags-color-hover;
	text-decoration: underline;
}
