//Tělo dokumentu
.article {
	&__header {

		&.-person {
			flex-direction: column;
			gap: 0 3rem;

			.article__image {
				width: auto;
				margin-left: auto;
				margin-right: auto;

				img {
					width: 180px;
					height: 180px;
					object-position: center;
					object-fit: cover;
					border-radius: 50%;
				}
			}

			@include min(600px) {
				flex-direction: row-reverse;
			}
		}

		.ot-10067 &,
		.ot-10210 & {
			position: relative;
			padding: 0 3% 4rem;

			&:after {
				position: relative;
				content: "";
				width: 435px;
				height: 0;
				padding-bottom: 22.75%;
				background: transparent url(/images/local/v-202503271058/ind-creation-ico.png) no-repeat center center;
				background-size: contain;
				margin-left: auto;
				max-width: 40%;
			}

			@include min(600px) {
				padding: 0 5.5rem 4rem;
			}

			@include min(1024px) {
				padding: 0 12vw 5rem;
				padding-right: calc(12vw + 30%);

				&:after {
					position: absolute;
					top: 0;
					right: 5vw;
				}
			}

			@include min(1500px) {
				&:after {
					right: 10vw;
				}
			}
		}
	}

	&__heading {
		font-size: rem(50px);
		line-height: 1;
		margin: 0 0 0;
		font-weight: 500;
		font-family: $font-headings;
		color: $color-tertiary;

		@include min(900px) {
			font-size: rem(78px);
		}
	}

	&__approved {
		padding: 1em 0 0;
	}

	&__effectiveness {}

	&__approved,
	&__effectiveness {

		h2.element-type-heading,
		.vismo-validation-wrapper,
		.vismo-validation-wrapper>* {
			font-size: 1em;
		}
	}

	&__footer {
		margin: 1.5em 0 2em;
		padding: 0;

		&.-hide-for-visitors {
			display: none;

			[vuedart] & {
				display: block;
			}
		}

		div {
			display: inline-block;

			input[type="date"],
			input[type="time"] {
				display: inline;
				border: 0;
				padding: 0 .5em;
				border-radius: 3px;
			}
		}

		.invalid ul {
			margin: 0;
			padding: 0 0 0 .5em;
		}
	}

	&__perex {
		font-size: rem(20px);
		margin-bottom: 1.5em;
	}

	&__image {
		margin-left: auto;
		margin-right: auto;
	}

	&__inner-body {
		@include min(800px) {
			display: flex;
			gap: 4vw;

			&__left {
				flex: 1;
			}

			&__right {
				width: 250px;
				flex-shrink: 0;
				align-self: flex-end;

				@include min(1024px) {
					width: 350px;
				}

				@include min(1280px) {
					width: 450px;
				}
			}
		}
	}

	&__body {
		margin-bottom: 0em;

		@include min(600px) {
			&--grid {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 3rem 0;
				margin-bottom: 3rem;

				&.-gap {
					gap: 5vw;
					grid-gap: 5vw;
					grid-template-columns: 1fr 250px;

					@include min(1024px) {
						grid-template-columns: 1fr 1fr;
					}
				}
			}
		}

		&.organisational-folder.-contact-us {

			h2.element-type-heading,
			.org-opening-hours__title.-size-h2 {
				border-bottom: 1px solid #c4c4c4;
				padding: 0 0 1.25rem;
				margin: 0 0 1.5rem;
				line-height: 1.15;
				display: block;

				&::after {
					content: ":";
				}
			}

			.article-inline-headers h3.element-type-heading {
				vertical-align: baseline;
				display: inline-flex;
				font-weight: 700;
				margin: 0 0.5rem 0.25rem 0;
				line-height: 1.6;
				min-width: 2rem;

				+.text-component {
					margin: 0;
					display: inline;
				}
			}

			.personal-data {
				&__list.list {
					padding-right: 1rem;
				}

				&__item {
					font-size: rem(18px);
					margin-bottom: 0.5rem;
				}

				&__link {
					font-weight: 600;
				}

				&__description {
					font-size: rem(13px);
					font-weight: 500;
					color: #838383;
					margin-bottom: 0;
				}
			}

			.org-adress {
				font-size: rem(18px);
				font-weight: 700;

				&__title {
					display: none;
				}

				&__description {
					font-size: rem(13px);
					font-weight: 500;
					color: #838383;
					margin: 0;
				}
			}

			.org-contacts {
				&__list {
					display: flex;
					flex-direction: column;
					gap: 0 1rem;
					margin-bottom: 0;

					@include min(550px) {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						gap: 0 3rem;
						max-width: 1100px;
					}

					@include min(800px) {
						grid-template-columns: repeat(3, 1fr);
					}
				}

				&__item {
					padding-left: 0;
					margin-bottom: 2rem;
					flex-grow: 1;
				}

				&__link {
					display: inline-flex;
					flex-direction: column;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}

				&__title {
					padding-top: 1rem;
					margin: 0;
					font-family: $font-body;
					font-weight: 500;
				}

				&__thumbnail {
					position: relative;
				}

				&__contact {
					font-size: rem(18px);
					margin-top: 0;

					@include link() {
						font-weight: normal;
						color: #838383;
					}

					span {
						display: block;
						color: #838383;
						margin: 0px 0 0.75rem;

						&:not(:first-of-type):before {
							background-color: transparent;
							width: auto;
							height: auto;
							margin: 0;
						}
					}
				}

				&__type {
					font-size: rem(13px);
					font-weight: 500;
				}

				&__email {
					&::before {
						display: block;
						font-size: rem(13px);
						font-weight: 800;
						color: #2e2e2e;
						content: "E-mail:";
					}
				}

				&__phone {
					&::before {
						display: block;
						font-size: rem(13px);
						font-weight: 800;
						color: #2e2e2e;
						content: "Telefon:";
					}
				}
			}

			.org-adress-container {
				&__item {
					margin-bottom: 2rem;
				}
			}

			.article__related-links {
				.article-link {
					position: relative;
					color: $color-brand;
					font-weight: 800;
					text-decoration: none;

					&:hover {
						text-decoration: underline;

						&::after {
							background-position-x: -100%;
						}
					}

					&__title {
						font-size: rem(24px);
					}

					&::after {
						content: "";
						display: inline-block;
						width: 16px;
						height: 11px;
						background: transparent url(/images/local/v-202503271058/main-nav-arrow.png) no-repeat left 50%;
						transition: background-position .1s ease-in;
						margin-left: 1rem;
					}
				}
			}

			@include min(600px) {

				.article__ico,
				.article__dic,
				.article__databox,
				.org-adress-container__item {
					padding-right: 1rem;
				}

				.org-adress-container {
					&__list {
						display: grid;
						grid-template-columns: 1fr 1fr;
					}
				}
			}

			@include min(1024px) {
				&::before {
					position: absolute;
					right: 0;
					content: "";
					width: 745px;
					height: 618px;
					background: transparent url(/images/local/v-202503271058/article-contact-us-bg.png) no-repeat center center;
					background-size: contain;
					z-index: -1;
					max-width: 39vw;
				}

				&::after {
					position: absolute;
					top: 0%;
					right: 0;
					content: "";
					width: 314px;
					height: 1645px;
					background: transparent url(/images/local/v-202503271058/article-contact-us-bg_2.png) no-repeat 100% 30%;
					background-size: contain;
					z-index: -2;
					max-width: 15vw;

					@include min(1680px) {
						top: -13%;
					}
				}
			}
		}
	}

	&__section {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		padding: 0 3% 0;

		.lottie-container {
			position: relative;
			z-index: 90;
		}

		&-ofh {
			overflow: hidden;
		}

		.-long-article & {
			position: relative;
			padding-left: 0;
			padding-right: 0;
			gap: 0;
			padding: 0 0;

			.text-component {
				margin: 2rem 0;
				padding-bottom: 12px;
			}
			/*@include min(1024px){
				.text-component::before {
					position: sticky;
					display: block;
					content: "";
					top: 50vh;
					bottom: 0;
					left: -1.75rem;
					width: 12px;
					height: 12px;
					flex-shrink: 0;
					transform: translate(calc(-50% - 1.75rem), 0%);
					background-color: #2e2e2e;
					border-radius: 50%;
					z-index: 20;
					opacity: 0;
					transition: opacity .5s ease-in-out;
				}

				&::after {
					position: absolute;
					content: "";
					top: 2rem;
					bottom: 2rem;
					left: -1.75rem;
					border-left: 1px solid #c4c4c4;
				}
				&.-visible .text-component::before {
					opacity: 1;
				}
			}*/

			.text-component {
				position: relative;
				overflow: visible;
				padding-bottom: 0;
			}

			&:nth-of-type(odd) {
				.text-component {
					margin: 2rem 0;
					background-color: #f8f8f8;

					&::after {
						content: "";
						position: absolute;
						width: 900rem;
						left: -100rem;
						top: -2rem;
						bottom: -2rem;
						background-color: #f8f8f8;
						z-index: -1;
					}
				}
			}
		}
		.text-component {
			margin-bottom: 0;
			padding-bottom: 2rem;
		}

		@include min(600px) {
			padding: 0 5.5rem 4rem;

			h2.element-type-heading {
				position: absolute;
				left: 0;
				writing-mode: vertical-rl;
				text-orientation: mixed;
				transform: rotate(-180deg) translate(-50%, 50%);
				white-space: nowrap;

				@include min(1024px) {
					left: 1vw;
				}

				@include min(1500px) {
					left: 3%;
				}
			}

			>.dev-element-block {
				>div {
					position: relative;

					&::before {
						position: sticky;
						display: block;
						content: "";
						top: 50vh;
						bottom: 0;
						left: -1.75rem;
						width: 12px;
						height: 12px;
						transform: translate(calc(-50% - 1.75rem), 0%);
						background-color: #2e2e2e;
						border-radius: 50%;
						z-index: 20;
						opacity: 0;
						transition: opacity .5s ease-in-out;
					}

					&::after {
						position: absolute;
						content: "";
						top: 0;
						bottom: 0;
						left: -1.75rem;
						border-left: 1px solid #c4c4c4;
					}

					@include min(1024px) {

						&::before,
						&::after {
							left: -4vw;
						}

						&::before {
							transform: translate(calc(-50% - 3.95vw), 0%);
						}
					}
				}
			}

			&.-visible {
				>.dev-element-block>div::before {
					opacity: 1;
				}
			}

			&.-info-arch,
			&.-zps {
				padding-top: 4rem;
			}

		}

		@include min(1024px) {
			align-items: center;
			flex-wrap: nowrap;
			gap: 2rem;
			padding: 0 12vw;

			&.-zps {
				padding-bottom: 8rem;

				.dev-element-block {
					max-width: 475px;
				}
			}

			&.-info-arch {
				padding-top: 4rem;
				padding-bottom: 2rem;

				.lottie-container {
					width: 100%;
					max-width: 500px;
				}
			}

			&.-info-arch,
			&.-wireframe {
				>.dev-element-block {
					width: 50%;

					.text-component {
						max-width: 475px;
					}
				}

				>.article__section-right {
					width: 50%;
				}
			}
		}

		&.-zps h3.element-type-heading::before {
			content: "01";
		}

		&.-info-arch h3.element-type-heading::before {
			content: "02";
		}

		&.-wireframe h3.element-type-heading::before {
			content: "03";
		}

		&.-graphic-design h3.element-type-heading::before {
			content: "04";
		}

		&.-testing h3.element-type-heading::before {
			content: "05";
		}

		&.-publish h3.element-type-heading::before {
			content: "06";
		}

		&.-zps {
			align-items: center;
			justify-content: center;
			padding-bottom: 2rem;

			.lottie-container {
				flex-grow: 1;
				max-width: 700px;
			}

			@include min(1024px) {
				justify-content: flex-start;
			}
		}

		&.-info-arch {
			padding-top: 4rem;
			background-color: #f8f8f8;

			.article__section-right {
				padding: 3rem 0 4rem;
				position: relative;

				.dev-element-block {
					max-width: 500px;
				}
			}
		}

		&.-wireframe {
			padding-top: 4rem;
			padding-bottom: 2rem;

			@include min(600px) {
				padding-top: 8rem;
			}

			@include min(1024px) {
				min-height: 650px;
				padding-bottom: 2rem;

				&::before {
					position: absolute;
					content: "";
					right: 0;
					width: 301px;
					height: 645px;
					background: transparent url(/images/local/v-202503271058/wireframe-bg-right.png) no-repeat right top;
					z-index: -1;
					max-width: 16%;
					background-size: contain;
				}

				.lottie-container {
					width: 100%;
					max-width: 236px;
					min-width: 200px;
				}

				.article__section-right {
					.dev-element-block {
						max-width: 300px;
					}
				}
			}
		}

		&.-responsive {
			background-color: $color-brand;
			color: #fff;
			padding-top: 8rem;
			padding-bottom: 5rem;
			margin-top: 10rem;
			margin-bottom: 7rem;

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: url(/images/local/v-202503271058/responsive-trsatko-left.png);
				transform: translate(-30%, -50%);
			}

			h2.element-type-heading {
				color: #fff;
			}

			h3.element-type-heading {
				font-size: 3.5rem;
				color: #fff;
				font-family: $font-headings;
				font-weight: 500;
				position: relative;
				z-index: 20;
				line-height: 1;
			}

			.dev-element-block>div {

				&::before,
				&::after {
					content: normal;
				}
			}

			.article__section-right {
				position: relative;
				flex-shrink: 0;
				margin-top: auto;
				margin-bottom: -200px;

				.lottie-container {
					position: absolute;
					bottom: 5%;
					right: 0;
					width: 340px;
					max-width: 100%;
				}
			}

			.text-component {
				position: relative;
				z-index: 20;
			}

			@include min(600px) {
				h2.element-type-heading {
					transform: rotate(-180deg) translate(-50%, -75%);
				}
			}

			@include min(1024px) {
				flex-direction: row-reverse;
				margin-bottom: 280px;
				padding: 4rem calc(11vw + 4%) 0 11vw;
				gap: 0 6%;
				margin-top: 10rem;
				margin-bottom: 16rem;

				h3.element-type-heading {
					font-size: 5vw;
				}

				>.dev-element-block {
					padding: 40px 0;
					min-height: 470px;

					.text-component {
						padding-bottom: 3rem;
					}
				}

				.article__section-right {
					width: auto;

					.lottie-container {
						left: 80%;
						right: auto;
					}
				}

				&::after {
					position: absolute;
					right: 0;
					bottom: 0;
					content: url(/images/local/v-202503271058/responsive-trsatko-right.png);
					transform: translate(40%, 50%);
					max-width: 40vw;
				}
			}

			@include min(1280px) {
				.article__section-right {
					padding-left: 8%;
				}
			}

			@include min(1560px) {
				h3.element-type-heading {
					font-size: rem(78px);
				}
			}
		}

		&.-graphic-design {
			align-items: flex-start;
			gap: 10%;
			padding-top: 6rem;
			//padding-bottom: 16rem;
			padding-bottom: 2rem;

			.article__section-right {
				min-height: 2222px;
				justify-content: center;
				align-items: flex-start;

				.article__graphic-design-animation {
					position: sticky;
					top: 12.5vh;

					&__inner {
						position: relative;
						display: flex;

						img {
							max-height: 75vh;
						}

						img:nth-of-type(1) {
							width: auto;
							height: 100%;
							border-radius: 12px;
							box-shadow: 0 0 250px rgba(#1d5b87, .25);
						}

						img:nth-of-type(2) {
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0;
							object-fit: cover;
							object-position: bottom;
							top: var(--grd-anim-scroll);
							max-height: calc(100% - var(--grd-anim-scroll));
						}
					}
				}
			}

			@include min(1024px) {
				//padding-top: 15rem;
				padding-top: 2rem;

				>.dev-element-block {
					max-width: 480px;
					min-height: 2222px;

					>div {
						position: sticky;
						top: 12.5vh;
					}
				}

				&:after {
					position: absolute;
					content: "";
					right: 0;
					bottom: 0;
					background: transparent url(/images/local/v-202503271058/graphic-design-bg-right.png) no-repeat right bottom;
					width: 309px;
					height: 829px;
					z-index: -1;
					background-size: contain;
					max-width: 25%;
				}
			}
		}

		&.-principle {
			background-color: $color-dark;
			color: #fff;
			padding-top: 11.5rem;
			padding-bottom: 8.5rem;
			margin-top: 14rem;
			margin-bottom: 50vw;

			h2.element-type-heading,
			h3.element-type-heading {
				color: #fff;
			}

			h3.element-type-heading {
				position: relative;
				z-index: 5;
				font-size: 3.5rem;
				font-family: $font-headings;
				font-weight: 500;
			}

			>.dev-element-block {

				>div::before,
				>div::after {
					content: normal;
				}

				.text-component {
					position: relative;
					z-index: 5;
				}
			}

			.article__section-right {
				.principle__img {
					position: absolute;
					min-width: max-content;
					display: none;

					&.-circle-1 {
						display: block;
						left: 0;
						top: 0;
						transform: translate(0%, -50%);
						min-width: auto;
					}

					&.-circle-2 {
						display: block;
						right: 0;
						bottom: 0;
						transform: translate(0%, 60%);
						min-width: auto;

						@include min(800px) {
							transform: translate(30%, 50%);
						}
					}
				}
			}

			.more {
				a {
					transition: background-color .2s ease-in-out;
				}

				@include link-over {
					background-color: darken($color-brand, 7);
				}
			}

			@include min(800px) {
				margin-bottom: 14rem;
			}

			@include min(900px) {
				h3.element-type-heading {
					font-size: rem(78px);
				}
			}

			@include min(1024px) {
				display: grid;
				grid-template-columns: 1fr 0px;
				padding-top: 10.5rem;
				padding-bottom: 8.5rem;
				margin-bottom: 24rem;

				>.dev-element-block {
					padding-left: 15.5%;
					max-width: 52vw;
					padding-bottom: 6rem;
					min-height: 40rem;

					&::before {
						position: absolute;
						left: 0;
						content: url(/images/local/v-202503271058/principle-bg-top.png);
						transform: translateY(calc(-95% - 10.5rem));
					}
				}

				.article__section-right {
					position: relative;
					max-width: none;
					flex-shrink: 0;
					height: 100%;

					&::after {
						position: absolute;
						margin-top: 20rem;
						right: 67vw;
						bottom: -8.5rem;
						content: "";
						width: 683px;
						height: 729px;
						background: transparent url(/images/local/v-202503271058/principle-bg-left.png) no-repeat left bottom;
					}

					.principle__img {
						display: block;

						&.-circle-1 {
							right: 40vw;
							top: -12.5rem;
							transform: translate(50%, -50%);
							left: auto;
						}

						&.-circle-2 {
							left: 10vw;
							top: -1.5rem;
							right: auto;
							bottom: auto;
							min-width: max-content;
							transform: translate(-70%, -50%);

							@include min(1250px) {
								left: 0vw;
							}
						}

						&.-circle-3 {
							left: 7vw;
							top: 80%;
							transform: translate(-50%, -50%);
						}

						&.-circle-4 {
							right: 0;
							bottom: -8.5rem;
							transform: translate(0%, 50%);
							min-width: 50vw;

							@include min(1460px) {
								min-width: max-content;
							}
						}
					}
				}
			}
		}

		&.-testing {
			margin-top: 2rem;
			padding-top: 0;
			padding-bottom: 12.5rem;

			>.dev-element-block {
				max-width: 480px;
			}

			.article__section-right {
				padding-top: 12.5%;
				justify-content: center;
				flex-grow: 1;
			}

			@include min(600px) {
				margin-top: 0rem;
			}

			@include min(1024px) {
				margin-top: -8rem;
				padding-bottom: 4rem;
			}
		}

		&.-publish {
			position: relative;
			padding-bottom: 0;

			h2.element-type-heading {
				font-size: 3.5rem;
				font-family: $font-headings;
				font-weight: 500;
				position: relative !important;
				z-index: 20;
				writing-mode: initial !important;
				text-orientation: initial !important;
				transform: none !important;
				left: auto;
				padding-bottom: 2rem;

				&::before {
					content: "06";
					color: $color-brand;
					margin-right: 5.5rem;
					display: block;
				}

				&::after {
					content: " =)";
					color: $color-brand;
					margin-left: 1rem;
				}
			}

			&::after {
				position: absolute;
				content: "";
				top: 105%;
				right: 16%;
				background: transparent url(/images/local/v-202503271058/publish-fajfka.svg) no-repeat center center;
				background-size: contain;
				width: 547px;
				height: 344px;
				max-width: 29%;
			}

			@include min(900px) {
				padding-top: 6rem;

				h2.element-type-heading {
					font-size: rem(78px);

					&::before {
						display: inline;
					}
				}

				.text-component {
					padding-left: 9.75rem;
				}
			}
		}
	}

	&__section-right {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;

		.text-component {
			font-size: rem(18px);
		}

		&.-column {
			flex-direction: column;
		}

		h2.element-type-heading {
			//font-size: rem(24px);
			//color: $color-brand;
			//text-transform: uppercase;
			position: static !important;
			writing-mode: initial !important;
			text-orientation: initial !important;
			transform: none !important;
			margin-top: 1rem;
			margin-bottom: 1rem;
			text-align: center;
		}

		@include min(1024px) {
			flex-wrap: nowrap;
			gap: 2rem 3rem;
			justify-content: flex-start;

			h2.element-type-heading {
				text-align: inherit;
			}

			&.-column {
				align-items: flex-start;
			}
		}
	}

	&__author {
		text-align: left;
		clear: both;
		margin: 1.5em 0;
		display: inline;
		font-style: normal;

	}

	&__datespan {
		margin-bottom: 3em;
	}

	&__date {
		font-weight: bold;
	}
	&__with-same-tags{
		.document__link{
			display: flex;
			flex-direction: column;
		}
	}
}

//Tělo události

.article-event {
	&__perex {
		margin-top: .5em;
	}

	&__types {
		.list {
			display: flex !important;
			flex-wrap: wrap;
			gap: 8px 10px;
			margin: 0 0 1rem;

			&__item {
				display: inline-flex;
				margin: 0;
				line-height: 1.25;
				align-items: center;
			}
		}
	}
}

.article-contact-us__gmap {
	font-size: rem(15px);
	font-weight: 700;
	color: #383838;
	text-align: center;

	iframe {
		position: absolute;
		left: 0;
		max-width: 100vw;
	}

	p {
		padding-top: calc(400px + 2rem);
		margin: 0 0 0;
	}
}

.org-contacts {
	&__thumbnail {
		top: 0;
	}

	&__perex {
		margin: 0 0;
	}

	&__contact {
		margin-top: .25rem;
	}
}
