.tiles {
	&__list {
		display: flex;
		flex-wrap: wrap;
		padding: 0 0 1rem;
		margin: 0 -.5rem;
		margin-top: 1rem;
		list-style-type: none;
		.ot-10200 &{
			margin-bottom: 1.5rem;
		}
	}
}

.tile {
	&__item {
		position: relative;
		width: 100%;
		font-size: rem(19px);
		font-weight: 700;
		padding: 0 .5rem 1rem;

		@include min(500px) {
			width: 50%;
		}

		@include min(1024px) {
			width: 33.3333333%;

			.-nosidebar & {
				width: 25%;
			}
		}

		//.organisational-folder.-contact-us & {
		//	@include min(1024px) {
		//		width: 50%;
		//	}
		//}
		.info-hidden{
			position: absolute!important;
			top: 0;
			right: 0.5rem;
		}
		.info-outside{
			width: 26px;
			height: 26px;
			padding: 0;
			&:before{
				top: 0;
			}
		}
	}


	&__link {
		position: relative;
		padding: 1.25rem .5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 100%;
		border-radius: 5px;
		transition: background-color ease-in-out .3s;

		//.organisational-folder.-contact-us & {
		//	min-height: 80px;
		//	font-weight: 400;
		//}

		@include link(&) {
			color: #fff;
			background-color: $color-brand;
			text-decoration: none;
		}

		@include link-over(&) {
			background-color: $color-dark;
		}
	}
}
