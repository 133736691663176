
.gallery {
   list-style-type: none;
   margin: 0 0 2em;
   padding: 0;
   grid-gap: 25px;

   &__item {
      position: relative;
   }
   
   &__link {
      height: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      text-decoration: none;

      &[data-image-desc] {
         &::after {
            content: attr(data-image-desc);
            line-height: 1.3;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
            background: rgba(#000000, .6);
            backdrop-filter: blur(0);
            opacity: 0;
            padding: .7em 1em;
            color: #ffffff;
            transition: backdrop-filter .2s ease-out, opacity .25s ease-out;
            overflow: hidden;
            text-overflow: ellipsis;
         }
         
         &:hover::after {
            opacity: 1;
            backdrop-filter: blur(12px);
         }
         
         &:focus-within::after {
            opacity: 1;
            backdrop-filter: blur(12px);
         }
      }
   }

   &__image {
      object-fit: cover;
      flex-grow: 1;
      transition: transform .3s ease-out;
      transform: translate3d(0,0,1px) scale(1);
   }

   &__link:not(.-placeholder):hover,
   &__link:not(.-placeholder):focus,
   &__link:not(.-placeholder):active {
      z-index: 2;

      .gallery__image {
         transform: translate3d(0,0,1px) scale(1.15);
      }
   }

   &__link.-placeholder {
      background-color: #eee;
   }
}