

$__ECCO_FLEXBOX_CLASSES: () !global;

@mixin flexbox-classes( $class-str ) {
	@if not index($__ECCO_FLEXBOX_CLASSES, $class-str) {
		.fl-#{$class-str} {
			display: flex;
		}

		.fl-g-#{$class-str} {
			flex-grow: 1;
		}

		.fl-s-#{$class-str} {
			flex-shrink: 1;
		}

		.fl-full-#{$class-str} {
			flex-basis: 100%;
			width: 100%;
		}

		.fl-wr-#{$class-str} {
			flex-wrap: wrap;
		}

		.fl-col-#{$class-str} {
			flex-direction: column;
		}

		.fl-col-r-#{$class-str} {
			flex-direction: column-reverse;
		}

		.fl-row-#{$class-str} {
			flex-direction: row;
		}

		.fl-row-r-#{$class-str} {
			flex-direction: row-reverse;
		}

		.fl-nwr-#{$class-str} {
			flex-wrap: nowrap;
		}

		.fl-jsb-#{$class-str} {
			justify-content: space-between;
		}

		.fl-ch-#{$class-str} {
			justify-content: center;
		}

		.fl-cv-#{$class-str} {
			align-items: center;
		}

		.fl-sv-#{$class-str} {
			align-items: stretch;
		}

		$__ECCO_FLEXBOX_CLASSES: append($__ECCO_FLEXBOX_CLASSES, $class-str) !global;
	} @else {
		@error ".#{$class-str} has already been declared";
	}
}

@mixin declare-flexbox-classes( $min-width, $max-width: null ) {
	$min: if( unitless($min-width), to-length($min-width, "px"), $min-width );
	$max: if( $max-width == null, null, if( unitless($max-width), to-length($max-width, "px"), $max-width ) );

	$min: ">= #{$min-width}";
	$max: if( $max-width == null, null, "<= #{$max-width}" );

	$clean-min: strip-unit($min-width);

	@if $max == null {
		@include bp($min) {
			@include flexbox-classes($clean-min);
			//@debug "Deklarovany tridy pro sirku #{$min}.";
		}
	} @else {
		$clean-max: strip-unit($max-width);

		@include bp($min, $max) {
			$cl-str: "#{$clean-min}-#{$clean-max}";
			@include flexbox-classes($cl-str);
			//@debug "Deklarovany tridy pro rozmezi #{$min} - #{$max}.";
		}
	}
}

@mixin flex( $shortcuts... ) {
	$defs: (
		"df":    (display, flex),
		"dif":   (display, inline-flex),
		"fg0":   (flex-grow, 0),
		"fg1":   (flex-grow, 1),
		"fs0":   (flex-shrink, 0),
		"fs1":   (flex-shrink, 1),
		"fb1":   (flex-basis, 1),
		"fdr":   (flex-direction, row),
		"fdrr":  (flex-direction, row-reverse),
		"fdc":   (flex-direction, column),
		"fdcr":  (flex-direction, column-reverse),
		"jcfs":  (justify-content, flex-start),
		"jcfe":  (justify-content, flex-end),
		"jcsb":  (justify-content, space-between),
		"jcsa":  (justify-content, space-around),
		"jcc":   (justify-content, center),
		"aic":   (align-items, center),
		"ais":   (align-items, stretch),
		"aifs":  (align-items, flex-start),
		"aife":  (align-items, flex-end),
		"fww":   (flex-wrap, wrap),
		"fwn":   (flex-wrap, nowrap),
		"asfs":  (align-self, flex-start),
		"asfe":  (align-self, flex-end)
	);

	@each $key in $shortcuts {
		@if map-has-key($defs, $key) {
			$styles: map-get($defs, $key);
			$prop: nth($styles, 1);
			$value: nth($styles, 2);

			#{$prop}: #{$value};
		} @else {
			@error "Neplatná zkratka";
		}
	}
}