.main-wrapper.ot-10114,
.main-wrapper.ot-10426{
	max-width: 1920px;
	width: 100%;
	//color: $color-base;
	.main{
		max-width: 100%;
	}
	#appForObjectToolbar{
		width: 90%;
		max-width: $max-width;
		margin: 0 auto;
	}
	.article-header__kiosek{
		display: flex;
		flex-direction: column;

		.article__heading{
			max-width: 6em;
			@include min(550px){
				max-width: none;
			}
			@include min(1024px){
				max-width: 6em;
			}
		}

		&-halve{
			flex-grow: 1;
			figure{
				margin: 0 auto;
			}
		}
		.more{
			@include flex(df, fww);
			gap: 1rem;
			&__button{
				flex: 1;
				@include flex(df,aic,jcc);
			}
		}
		@include min(1024px){
			flex-wrap: nowrap;
			flex-direction: row;
			&-halve{
				figure{
					margin: 0;
					img{
						margin-bottom: -10%;
					}
				}
				&:first-of-type(){
					max-width: 40%;
				}
				&:last-of-type(){
					margin-right: -16%;
					margin-left: -5%;
					z-index: -1;
				}
			}
		}
	}

	.article__text{
		margin-bottom: 4rem;
		@include min(1100px){
			margin-bottom: 8rem;
		}
		.element-type-heading{
			text-align: center;
			margin-bottom: 3rem;
			font-family: $font-body;
			font-size: rem(28px);
			font-weight: 800;
			max-width: 90%;
			margin-left: auto;
			margin-right: auto;
		}
		&.history{
			margin: 2rem auto 3rem;
    		max-width: 28em;
			.text-component,
			.textElm{
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				gap: 1rem;
			}
			b{
				font-family: $font-headings;
				font-size: rem(70px);
				font-weight: 500;
				color: $color-brand;
				line-height: 1;
			}
			@include min(1024px){
				margin: 15% 0;
				max-width: none;
			}
		}
		&.valuation{
			.text-component{
				overflow: visible;
			}
			.list{
				display: grid;
				grid-template-columns: 1fr;
				padding-left: 4rem;
				&__item{
					strong{
						position: relative;
						color: $color-dark;
						font-weight: 800;
						font-size: rem(24px);
						&::before{
							position: absolute;
							content: "";
							top: -0.5rem;
							left: -4rem;
							width: 42px;
							height: 42px;
							background: transparent url(/images/local/kiosky/v-202503271058/aprowed.png) no-repeat center center;
						}
					}
				}
				@include min(800px){
					grid-template-columns: repeat(3, 1fr);
					gap: 4rem 5rem;
				}
				@include min(1024px){
					gap: 4rem 8%;
					li:nth-child(2) + li {
						grid-column-start: 1;
					}
				}
				@include min($max-width){
					padding-left: 0;
				}
			}
		}
		&.tips{
			.list{
				display: grid;
				@include min(350px){
					grid-template-columns: repeat(2, 1fr);
				}
				@include min(600px){
					grid-template-columns: repeat(4, 1fr);
				}
				@include min(1024px){
					grid-template-columns: repeat(8, 1fr);
				}
				&__item{
					position: relative;
					z-index: 2;
					margin-bottom: 0;
					figure{
						position: relative;
						margin: 0;
						img{
							width: 100%;
							display: block;
							margin: 0;
							object-fit: cover;
							object-position: center;
							max-height: 200px;
							@include min(600px){
								max-height: 250px;
							}
							@include min(1024px){
								max-height: none;
							}
						}
						&:before{
							content: "";
							position: absolute;
							@include fill();
							background-color: rgba(#000000, .6);
							transition: background-color .3s ease-in-out;
						}
					}
					strong{
						padding: 1rem;
						position: absolute;
						font-size: rem(24px);
						@include fill();
						@include flex(df, aic, jcc);
						text-align: center;
						color: #fff;
						font-weight: 800;
					}
					@include link-over(&){
						figure:before{
							background-color: rgba(#000000, .4);
						}
					}
				}
			}
		}
		&.main-properties{
			@include min(1024px){
				position: relative;
				&::before{
					position: absolute;
					content: "";
					top: -11rem;
					right: 0;
					width: 212px;
					padding-bottom: 601px;
					background: transparent url(/images/local/kiosky/main-properties/v-202503271058/bg.png) no-repeat 100% 0 / contain;
					max-width: 10%;
				}
			}
			.text-component{
				max-width: $max-width;
				margin: 0 auto;
				width: 90%;
			}
			.list{
				display: grid;
				grid-template-columns: 1fr;
				gap: 2rem;
				@include min(450px){
					grid-template-columns: repeat(2, 1fr);
				}
				@include min(800px){
					grid-template-columns: repeat(3, 1fr);
					gap: 5rem 8%;
				}
				&__item{
					position: relative;
					figure{
						position: relative;
						margin: 0;
						min-height: 106px;
						img{
							display: block;
							margin: 0 0 1.5rem;
						}
					}
					strong{
						font-size: rem(24px);
						text-align: center;
						font-weight: 800;
						color: $color-dark;
					}
				}
			}
		}
		&.properties{
			position: relative;
			background: transparent url(/images/local/kiosky/properties/v-202503271058/bg.png) no-repeat center bottom;
			padding-bottom: 4%;
			.text-component{
				max-width: $max-width;
				margin: 0 auto;
				width: 90%;
				overflow: visible;
				margin: 20% auto 0;
				@include min(1024px){
					margin: 16rem auto 7%;
				}
			}
			.list{
				display: grid;
				grid-template-columns: 1fr;
				gap: 2rem;
				&__item{
					position: relative;
					background-color: #fff;
					border-radius: 8px;
					box-shadow: 0 0 75px rgba(#000000, .25);
					padding: 0 8% 16%;
					figure{
						position: relative;
						margin: 0 0 1rem;
						img{
							display: block;
							margin: 1rem auto 0;
							@include min(600px){
								margin: -33% auto 0;
							}
						}
					}
					strong{
						font-size: rem(24px);
						text-align: center;
						font-weight: 800;
						color: $color-dark;
					}
				}
				@include min(600px){
					grid-template-columns: repeat(3, 1fr);
					&__item{
						&:nth-child(2){
							top: -5rem
						}
					}
				}
				@include min(1100px){
					gap: 5rem 6%;
				}
			}
			@include min(1700px){
				&::after{
					position: absolute;
					content: "";
					bottom: 3%;
					right: -4%;
					width: 314px;
					height: 522px;
					background: transparent url(/images/local/kiosky/properties/v-202503271058/kiosek.png) no-repeat center bottom / contain;
					max-width: 16%;
				}
			}
			@include min(1920px){
				&::after{
					right: 0;
				}
			}
		}
	}
	.more-info{
		position: relative;
		background: $color-brand url(/images/local/kiosky/more-info/v-202503271058/more-info-bg.png) no-repeat 95% center;
		color: #fff;
		border-radius: .5rem;
		margin-bottom: 4rem;
		@include min(1024px){
			margin-bottom: 8rem;
			&::after{
				position: absolute;
				content: "";
				top: 70%;
				left: 50%;
				transform: translateX(-50%);
				width: 1191px;
				height: 197px;
				max-width: 80%;
				z-index: -1;
				background: transparent url(/images/local/kiosky/more-info/v-202503271058/bottom-shadow.png) no-repeat center bottom / contain;
			}
		}
		.element-type-heading{
			text-align: center;
			@include min(1024px){
				text-align: left;
			}
		}
		.dev-element-block{
			display: grid;
			grid-template-columns: 1fr;
			gap: 2rem;
			min-height: 356px;
			align-items: center;
			padding: 4rem 8%;
			@include min(1024px){
				padding: 2rem 8%;
				grid-template-columns: 1fr 431px 1fr;
			}
		}
		.phone{
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.list{
				position: relative;
				align-items: center;
				margin-bottom: 0;
				&__item{
					margin-bottom: 0;
				}
				&__link{
					display: inline-block;
					font-size: rem(16px);
					background-color: #383838;
					padding: 1rem 1.5rem;
					border-radius: .5rem;
					@include link(&){
						text-decoration: none;
					}
					@include link-over(&){
						text-decoration: underline;
					}
				}
			}
			@include min(1024px){
				&::before{
					position: absolute;
					bottom: -2rem;
					content: "";
					display: block;
					width: 100%;
					height: 530px;
					background: transparent url(/images/local/kiosky/more-info/v-202503271058/phone.png) no-repeat center bottom / contain;
				}
			}
		}
		.faq{
			text-align: center;
			font-size: rem(18px);
			font-weight: 300;
			@include link(){
				font-weight: 700;
				font-size: rem(24px);
			}
			@include min(1024px){
				text-align: right;
			}
		}
		.element-type-heading{
			color: #fff;
		}
		@include link(){
			color: #fff;
		}
	}
	.article__gallery{
		.element-type-heading{
			text-align: center;
			margin-bottom: 3rem;
			font-family: $font-body;
			font-size: rem(28px);
			font-weight: 800;
		}
	}
}
